function getEnvironment() {
  if (window.location.origin.indexOf('http://localhost:3000') > -1) {
    return 'dev';
  } else if (
    window.location.origin.indexOf('https://tool-dev.tap.verizonmedia.com') > -1
  ) {
    return 'integration';
  } else if (
    window.location.origin.indexOf('https://tool.tap.verizonmedia.com') > -1
  ) {
    return 'production';
  }
}

var environmentConfig = {
  dev: {
    apiURL: 'https://localhost:8443/v1',
    clientId: '6df0e444-5ab9-4513-9238-f0aab5915d6c',
    redirectURL: 'http://localhost:3000/login',
    appURL: window.location.origin,
    dashboardURL: window.location.origin + '/search',
    aasAuthenticatorURL:
      'https://identity.publishing.oath.com/oauth/38ddc0e1-d1f6-441a-a58c-486ded9bc63f/authorize?',
    isProduction: false,
    environment: 'Development'
  },
  integration: {
    apiURL:
      'https://dev-integration-api.comms-notifications-dev.aws.oath.cloud/v1',
    clientId: '6db01aec-916d-4291-8136-a1e407904c40',
    redirectURL: 'https://tool-dev.tap.verizonmedia.com/login',
    appURL: window.location.origin,
    dashboardURL: window.location.origin + '/search',
    aasAuthenticatorURL:
      'https://identity.publishing.oath.com/oauth/38ddc0e1-d1f6-441a-a58c-486ded9bc63f/authorize?',
    isProduction: false,
    environment: 'Integration'
  },
  production: {
    apiURL:
      'https://api-prod.comms-notifications-prod.aws.oath.cloud/v1',
    clientId: 'fa9ef384-afc8-4f38-9841-0246783019eb',
    redirectURL: 'https://tool.tap.verizonmedia.com/login',
    appURL: window.location.origin,
    dashboardURL: window.location.origin + '/search',
    aasAuthenticatorURL:
      'https://identity.publishing.oath.com/oauth/38ddc0e1-d1f6-441a-a58c-486ded9bc63f/authorize?',
    isProduction: true,
    environment: 'Production'
  }
};

export default environmentConfig[getEnvironment()];
