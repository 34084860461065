import axios from 'axios';
import config from '../utils/default-config';
import Cookies from 'universal-cookie';
import { ErrorHandler } from '../components/common/Common';

export function getToken(code) {
  var payload = {
    code: code
  };
  const url = config.apiURL + '/tool/auth';
  return axios({
    url: url,
    method: 'POST',
    data: payload
  })
    .then(response => {
      const token = response.data;
      console.log('token from auth api', token);
      const cookies = new Cookies();
      cookies.set('accessToken', token.accessToken, {
        path: '/',
        maxAge: token.accessTokenExpiry
      });
      cookies.set('refreshToken', token.refreshToken, {
        path: '/',
        maxAge: token.refreshTokenExpiry
      });
      console.log(cookies.get('accessToken'));
      console.log(cookies.get('refreshToken'));
    })
    .catch(error => {
      ErrorHandler(error);
    });
}
