import React, { Component } from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';

export default class InputField extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(this.props.name, e.target.value);
  }

  render() {
    const {
      displayName,
      id,
      name,
      value,
      required,
      placeholder,
      validator
    } = this.props;
    return (
      <FormGroup
        id={id}
        inline={false}
        label={displayName}
        labelFor={id}
        labelInfo={displayName && '(required)'}
      >
        <InputGroup
          id={id}
          type="text"
          large={false}
          name={name}
          value={value}
          onChange={this.handleChange}
          required={required}
          placeholder={placeholder}
        />
        {validator &&
          validator.message(id, value, 'required', {
            className: 'note-alert-color'
          })}
      </FormGroup>
    );
  }
}
