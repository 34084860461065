import React, { Component } from 'react';
import { Spinner, H5 } from '@blueprintjs/core';
import SearchResultItem from './SearchResultItem';
import Message from '../common/Message';

class SearchResultContainer extends Component {
  render() {
    const { searchResult, msgType, error } = this.props;
    let result;
    switch (msgType) {
      case 'data':
        const registrations = searchResult.DDB.registrations;
        registrations.sort((a, b) => {
          if (a.association.lastUpdated && b.association.lastUpdated) {
            return a.association.lastUpdated < b.association.lastUpdated
              ? 1
              : -1;
          }
          return 0;
        });
        result = registrations.map((item, index) => (
          <SearchResultItem key={index} index={index} result={item} />
        ));
        break;
      case 'generalError':
        result = <Message type="alert-danger" message={error} />;
        break;
      case 'errorCode':
        let detail = (
          <div>
            <span>{error}. Check error code detail in this </span>
            <a href="https://git.ouroath.com/rivendell/rivendell/blob/master/common/src/main/java/com/oath/rivendell/exception/RivendellErrorCode.java">
              Doc
            </a>
            <span>.</span>
          </div>
        );
        result = <Message type="alert-danger" message={detail} />;
        break;
      case 'searching':
        result = <Spinner size={50} />;
        break;
      default:
        result = '';
    }

    return (
      <div>
        {result && <H5>Search Results</H5>}
        {result}
      </div>
    );
  }
}

export default SearchResultContainer;
