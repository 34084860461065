import React, { Component } from 'react';
import { Card, H4, Divider } from '@blueprintjs/core';
import ViewInfoModal from './ViewInfoModal';

class TableRow extends Component {
  render() {
    const { propName, propValue } = this.props;
    return (
      <tr>
        <td className="nowrap">
          <b>{propName}</b>
        </td>
        <td className="nowrap">{propValue}</td>
      </tr>
    );
  }
}

class PushInfo extends Component {
  render() {
    const { pushInfo } = this.props;
    return (
      <div>
        <H4>Push Info</H4>
        <table>
          <tbody>
            <TableRow
              propName="Push Token:"
              propValue={pushInfo.pushToken}
            ></TableRow>
            <TableRow
              propName="Push Service:"
              propValue={pushInfo.pushService}
            ></TableRow>
          </tbody>
        </table>
      </div>
    );
  }
}

class AppInfo extends Component {
  render() {
    const { app } = this.props;
    return (
      <div>
        <H4>Application Info</H4>
        <table>
          <tbody>
            <TableRow propName="Id:" propValue={app.id}></TableRow>
            <TableRow propName="Version:" propValue={app.version}></TableRow>
            <TableRow propName="SDK Name:" propValue={app.sdkName}></TableRow>
            <TableRow
              propName="SDK Version:"
              propValue={app.sdkVersion}
            ></TableRow>
            <TableRow
              propName="Notification Enabled:"
              propValue={
                app.appNotificationsEnabled
                  ? app.appNotificationsEnabled.toString()
                  : 'false'
              }
            ></TableRow>
          </tbody>
        </table>
      </div>
    );
  }
}

class OsInfo extends Component {
  render() {
    const { os } = this.props;
    return (
      <div>
        <H4>OS Info</H4>
        <table>
          <tbody>
            <TableRow propName="Type:" propValue={os.type}></TableRow>
            <TableRow propName="Name:" propValue={os.name}></TableRow>
            <TableRow propName="Version:" propValue={os.version}></TableRow>
            <TableRow propName="Language:" propValue={os.language}></TableRow>
            <TableRow propName="Region:" propValue={os.region}></TableRow>
            <TableRow propName="API Level:" propValue={os.apiLevel}></TableRow>
            <TableRow propName="Timezone:" propValue={os.timezone}></TableRow>
            <TableRow
              propName="Notification Enabled:"
              propValue={
                os.osNotificationsEnabled
                  ? os.osNotificationsEnabled.toString()
                  : 'false'
              }
            ></TableRow>
          </tbody>
        </table>
      </div>
    );
  }
}

class Association extends Component {
  render() {
    const { association } = this.props;
    return (
      <div>
        <H4>Association</H4>
        <div>
          {association && (
            <table>
              <tbody>
                <TableRow
                  propName="Type:"
                  propValue={association.type}
                ></TableRow>
                <TableRow
                  propName="Value:"
                  propValue={association.value}
                ></TableRow>
              </tbody>
            </table>
          )}
          {!association && <p>No Associations found.</p>}
        </div>
      </div>
    );
  }
}

class Tags extends Component {
  render() {
    const { attributes } = this.props;
    const tagsList = JSON.parse(attributes).tags;

    let tags = '';
    if (tagsList && tagsList.length) {
      tags = tagsList.map((tag, key) => {
        return <li key={key}>{tag}</li>;
      });
      tags = <ul>{tags}</ul>;
    } else {
      tags = <p>No tags found.</p>;
    }
    return (
      <div>
        <H4>Tags</H4>
        {tags}
      </div>
    );
  }
}

class SearchResultItem extends Component {
  render() {
    const { index, result } = this.props;

    return (
      <div className="marign-bottom">
        <Card elevation="2" interactive={false}>
          <H4>Record: {index + 1}</H4>
          <div className="result-row">
            <div className="details display-flex max-height-25">
              <Card elevation="2" className="result-col">
                <Association association={result.association}></Association>
                <br />
                {result.association && (
                  <Tags attributes={result.association.attributes}></Tags>
                )}
              </Card>
              <Divider></Divider>
              <Card elevation="2" className="result-col">
                <PushInfo pushInfo={result.pushInfo}></PushInfo>
              </Card>
              <Divider></Divider>
              <Card elevation="2" className="result-col">
                <AppInfo app={result.app}></AppInfo>
              </Card>
              <Divider></Divider>
              <Card elevation="2" className="result-col">
                <OsInfo os={result.os}></OsInfo>
              </Card>
            </div>
          </div>
          <br />
          <div className="bp3-button-group bp3-fill">
            <ViewInfoModal data={result} />
          </div>
        </Card>
      </div>
    );
  }
}

export default SearchResultItem;
