import React, { Component } from 'react';
import config from '../../utils/default-config';
import './Search.css';
import Cookies from 'universal-cookie';
import SearchFormContainer from './SearchFormContainer';
import SearchResultContainer from './SearchResultContainer';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: [],
      error: undefined,
      msgType: undefined
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const cookies = new Cookies();
    const accessToken = cookies.get('accessToken');
    if (!accessToken) window.location.replace(config.appURL);
  }

  handleChange(error, msgType, searchResult, callback) {
    if (callback) {
      this.setState(
        {
          error: error,
          msgType: msgType,
          searchResult: searchResult
        },
        callback
      );
    } else {
      this.setState({
        error: error,
        msgType: msgType,
        searchResult: searchResult
      });
    }
  }

  render() {
    const { searchResult, error, msgType } = this.state;
    return (
      <div className="container">
        <div className="search-row">
          <div className="search-container">
            <SearchFormContainer
              onChange={this.handleChange}
              params={this.props.match.params}
              showAlert={this.props.showAlert}
            />
          </div>
        </div>
        <div className="search-row">
          <div className="search-results">
            <SearchResultContainer
              searchResult={searchResult}
              error={error}
              msgType={msgType}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Search;
