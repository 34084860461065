import React, { Component } from 'react';
import { Dialog, Button, Classes } from '@blueprintjs/core';
import SearchInfo from './SearchInfo';

export default class ViewInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }
  handleClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const { data } = this.props;
    return (
      <div className="full-width">
        <Button
          icon="eye-open"
          className="bp3-large bp3-minimal bp3-fill"
          onClick={this.handleOpen}
          text="Detail View"
        />
        <Dialog
          title="View Information"
          tabIndex={-1}
          icon="info-sign"
          onClose={this.handleClose}
          {...this.state}
          className="modal-half-width"
        >
          <div className={Classes.DIALOG_BODY}>
            <div className="modal-body">
              <div className="display-flex">
                <SearchInfo title="Result" content={data} />
              </div>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.handleClose}>Close</Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
