import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import config from '../../utils/default-config';
import logo from '../../assets/images/logo.png';
import { Logout } from '../common/Common';
import {
  Alignment,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Button
} from '@blueprintjs/core';
import './Common.css';

class Child extends Component {
  handlelogout = () => {
    Logout();
  };

  render() {
    return (
      <Button
        className={'bp3-button bp3-minimal bp3-large'}
        icon="user"
        text="Log Out"
        onClick={this.handlelogout}
      />
    );
  }
}

export default class Header extends Component {
  render() {
    const cookies = new Cookies();
    const accessToken = cookies.get('accessToken');
    const env = config.environment ? ' (' + config.environment + ')' : '';
    return (
      <Navbar className="bp3-light">
        <NavbarGroup align={Alignment.LEFT} className="half-width">
          <img
            src={logo}
            className="width-20 cursor-pointer"
            alt="RIVENDELL"
            onClick={() => window.location.replace(config.dashboardURL)}
          />
          <NavbarHeading>
            <b
              className="cursor-pointer"
              onClick={() => window.location.replace(config.dashboardURL)}
            >
              {env}
            </b>
          </NavbarHeading>
          <NavbarDivider />
          {accessToken && (
            <div>
              <Link
                className={'bp3-button bp3-minimal bp3-large bp3-icon-search'}
                to="/search"
              >
                Search
              </Link>
            </div>
          )}
        </NavbarGroup>
        <NavbarGroup align={Alignment.RIGHT}>
          <a
            className={'bp3-button bp3-minimal bp3-large bp3-icon-help'}
            rel="noopener noreferrer"
            target="_blank"
            href="https://git.ouroath.com/rivendell/rivendell-tool/wiki/User-Guide"
          >
            Help
          </a>
          {accessToken && <Child />}
        </NavbarGroup>
      </Navbar>
    );
  }
}
