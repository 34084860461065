import Cookies from 'universal-cookie';
import config from '../../utils/default-config';

function ErrorHandler(error) {
  if (error.response && error.response.status === 401) {
    Logout();
  }
}

function Logout() {
  const cookies = new Cookies();
  cookies.remove('accessToken');
  cookies.remove('refreshToken');
  window.location.replace(config.appURL);
}

export { ErrorHandler, Logout };
