import React, { Component } from 'react';
import { Select } from '@blueprintjs/select';
import { MenuItem, Button } from '@blueprintjs/core';
import './Common.css';

export default (class SelectBox extends Component {
  render() {
    const {
      items,
      itemRenderer,
      onItemSelect,
      selectedItem,
      name,
      label,
      defaultLabel,
      itemPredicate
    } = this.props;
    const selectedLabel = selectedItem[label];
    const selectedName = selectedItem[name]
      ? '(' + selectedItem[name] + ')'
      : '';
    return (
      <Select
        filterable={true}
        items={items}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={onItemSelect}
        itemRenderer={itemRenderer}
        popoverProps={{ minimal: true, popoverClassName: 'popoverList' }}
        itemPredicate={itemPredicate}
      >
        <Button
          icon="list"
          rightIcon="caret-down"
          text={
            selectedItem
              ? `${selectedLabel} ${selectedName}`
              : '(' + defaultLabel + ')'
          }
        />
      </Select>
    );
  }
});
